import moment from 'moment';

export default {
    campaigns: [
        {
            id: 1,
            name: 'Primeira votacao do ensino medio',
            group: 'Ensino medio geral',
            expiration: moment(new Date(), 'DD-MM-YYYY').add(1, 'days'),
            created_at: moment(new Date(), 'DD-MM-YYYY').add(-1, 'days'),
            finalized: false
        }
    ]
};
