<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>

        <div class="form-field-plus">
            <div class="field-plus-legend">Clique criar uma campanha</div>
            <i class="fa fa-long-arrow-right arrow1" aria-hidden="true"></i>
            <div>
                <v-btn fab dark color="secondary" @click="actionCreate()">
                    <v-icon dark> mdi-plus </v-icon>
                </v-btn>
            </div>
        </div>

        <v-alert
            text
            dense
            color="gray"
            icon="mdi-alert-circle-outline"
            border="left"
            v-if="!campaigns.length">
            Clique no botão abaixo criar uma campanha!
        </v-alert>

        <div class="box-table" v-else>
            <v-data-table
                :headers="headers"
                :items="campaigns"
                :page.sync="pagination.page"
                sort-by="name"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pagination.ageCount = $event">
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDateComputed(item.createdAt) }}
                </template>

                <template v-slot:[`item.expiration`]="{ item }">
                    {{ formatDateComputed(item.expiration) }}
                </template>

                <template v-slot:[`item.expired`]="{ item }">
                    <v-icon
                        :color="
                            getIconExpiredComputed(item.createdAt, item.expiration).color
                        ">
                        {{ getIconExpiredComputed(item.createdAt, item.expiration).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.finalized`]="{ item }">
                    <v-icon :color="getIconFinalizedComputed(item.finalized).color">
                        {{ getIconFinalizedComputed(item.finalized).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="action && item.id === itemIdAllowed" class="confirm">
                        <div>Confirma remoção?</div>
                        <div>
                            <v-btn
                                color="secondary"
                                class="btn-action option confirm-ok"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmDelete(item.id, true)">
                                Sim
                            </v-btn>

                            <v-btn
                                color="red"
                                class="btn-action option"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmDelete(item.id, false)">
                                Não
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-progress-circular
                            :size="20"
                            color="gray"
                            v-show="item.stateFinalized"
                            indeterminate>
                        </v-progress-circular>

                        <v-btn
                            v-show="!item.stateFinalized"
                            :title="getButtonFinalizedComputed(item.finalized).title"
                            class="btn-action"
                            x-small
                            elevation="1"
                            rounded
                            plain
                            :disabled="item.stateFinalized"
                            :color="getButtonFinalizedComputed(item.finalized).color"
                            @click="actionToggleStatusFinalized(item.id)">
                            {{ getButtonFinalizedComputed(item.finalized).title }}
                        </v-btn>

                        <v-btn @click="actionEdit(item.id)" icon title="Editar">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn icon title="Deleter" @click="actionDelete(item.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>

            <div class="text-center pt-2">
                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import campaignsMock from '@/modules/company/mocks/campaigns.company.mock';
    import { isAmbientGeralTests } from '@/configs/global.config';

    export default {
        name: 'ListCampaign',
        data() {
            return {
                isAmbientLocalTests: false,
                pagination: {
                    page: 1,
                    pageCount: 0,
                    itemsPerPage: 10
                },
                action: null,
                itemIdActivated: null,
                titles: {
                    sector: 'Campanhas'
                },
                headers: [
                    { text: 'Nome', value: 'name' },
                    { text: 'Grupo', value: 'campaign' },
                    { text: 'Dt. Criação', value: 'created_at', align: 'center' },
                    { text: 'Dt. Expiração', value: 'expiration', align: 'center' },
                    { text: 'Expirada?', value: 'expired', align: 'center' },
                    { text: 'Finalizada?', value: 'finalized', align: 'center' },
                    { text: 'Ações', value: 'actions', align: 'center', sortable: false }
                ],
                currentUserId: null,
                campaigns: []
            };
        },
        mounted() {
            this.geralLoading();
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser'
            }),
            formatDateComputed() {
                return (date) => {
                    return moment(date).format('DD/MM/YYYY HH:mm');
                };
            },
            getIconFinalizedComputed() {
                return (finalized) => {
                    return {
                        icon: 'mdi-bookmark-check',
                        color: finalized ? 'green' : '#d0d0d0'
                    };
                };
            },
            getIconExpiredComputed() {
                return (dateCreation, dateExpiration) => {
                    return {
                        icon: 'mdi-clock',
                        color: moment(dateExpiration).isBefore(dateCreation)
                            ? 'red'
                            : '#d0d0d0'
                    };
                };
            },
            getButtonFinalizedComputed() {
                return (finalized) => {
                    return {
                        color: finalized ? 'green' : 'red',
                        title: finalized ? 'Ativar' : 'Finalizar'
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            geralLoading() {
                if (this.isAmbientTests()) {
                    this.campaigns = campaignsMock.campaigns;
                } else {
                    this.serviceLoadingCampaigns();
                }
            },
            actionCreate() {
                this.$store.dispatch('setCampaignEditionMode', false);
                this.$store.dispatch('setCampaignId', null);
                this.$router.push({ name: 'campaignFormCompany' });
            },
            actionEdit(campaignId) {
                this.$store.dispatch('setCampaignEditionMode', true);
                this.$store.dispatch('setCampaignId', campaignId);
                this.$router.push({ name: 'campaignFormCompany' });
            },
            actionDelete(campaignId) {
                this.action = true;
                this.itemIdAllowed = campaignId;
            },
            mockActionDelete(campaignId) {
                this.campaigns = this.campaigns.filter((g) => g.id !== campaignId);
            },
            efectiveActionDelete(campaignId) {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['company']
                    .deleteCampaign(campaignId)
                    .then((_) => {
                        this.serviceLoadingCampaigns();
                    })
                    .catch((e) => {
                        consol.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            actionConfirmDelete(campaignId, confirm) {
                if (confirm) {
                    if (this.isAmbientTests()) {
                        this.mockActionDelete(campaignId);
                    } else {
                        this.efectiveActionDelete(campaignId);
                    }
                }

                this.action = false;
                this.itemIdAllowed = null;
            },
            async serviceToggleStatusFinalized(campaignId) {
                await this.$service.http['company']
                    .toggleStatusFinalizedCampaign(campaignId)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async actionToggleStatusFinalized(campaignId) {
                const campaignIndex = this.campaigns.findIndex(
                    (e) => e.id === campaignId
                );

                if (campaignIndex !== -1) {
                    this.campaigns[campaignIndex].stateFinalized = true;

                    await this.serviceToggleStatusFinalized(campaignId);

                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    this.campaigns[campaignIndex].finalized =
                        !this.campaigns[campaignIndex].finalized;

                    this.campaigns[campaignIndex].stateFinalized = false;
                }
            },
            dataTransform(data) {
                const campaigns = [];

                data.forEach((e) => {
                    campaigns.push({
                        id: e.id,
                        name: e.name,
                        campaign: e.name,
                        createdAt: e.createdAt,
                        expiration: e.expiration,
                        finalized: e.finalized
                    });
                });

                return campaigns;
            },
            serviceLoadingCampaigns() {
                this.$store.commit('setGlobalLoading', true);

                const currentUserId = this.user.id;

                this.$service.http['company']
                    .getListCampaigns(currentUserId)
                    .then((result) => {
                        const records = result.data.docs;

                        this.campaigns = this.dataTransform(records);

                        this.campaigns.forEach((_, i) => {
                            this.campaigns[i].index = i + 1;

                            this.$set(this.campaigns[i], 'stateFinalized', false);
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .confirm-ok {
        margin-right: 10px;
    }

    .sector-head {
        margin-bottom: 20px;
    }

    .btn-action {
        text-transform: none !important;
        font-weight: bold;
        letter-spacing: 0em;
    }

    .option {
        width: 30px !important;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }

    .form-field-plus {
        display: flex;
        position: fixed;
        align-items: center;
        right: 15px;
        bottom: 60px;
        z-index: 1000;
    }

    .field-plus-legend {
        font-size: 14px;
        color: #777777;
    }

    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(10px, 0);
        }
    }

    .arrow1 {
        animation: slide1 1s ease-in-out infinite;
        margin-left: 10px;
        margin-right: 20px;
    }
</style>
