var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"form-field-plus"},[_c('div',{staticClass:"field-plus-legend"},[_vm._v("Clique criar uma campanha")]),_c('i',{staticClass:"fa fa-long-arrow-right arrow1",attrs:{"aria-hidden":"true"}}),_c('div',[_c('v-btn',{attrs:{"fab":"","dark":"","color":"secondary"},on:{"click":function($event){return _vm.actionCreate()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]),(!_vm.campaigns.length)?_c('v-alert',{attrs:{"text":"","dense":"","color":"gray","icon":"mdi-alert-circle-outline","border":"left"}},[_vm._v(" Clique no botão abaixo criar uma campanha! ")]):_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.campaigns,"page":_vm.pagination.page,"sort-by":"name","items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.ageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateComputed(item.createdAt))+" ")]}},{key:"item.expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateComputed(item.expiration))+" ")]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconExpiredComputed(item.createdAt, item.expiration).color}},[_vm._v(" "+_vm._s(_vm.getIconExpiredComputed(item.createdAt, item.expiration).icon)+" ")])]}},{key:"item.finalized",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconFinalizedComputed(item.finalized).color}},[_vm._v(" "+_vm._s(_vm.getIconFinalizedComputed(item.finalized).icon)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.action && item.id === _vm.itemIdAllowed)?_c('div',{staticClass:"confirm"},[_c('div',[_vm._v("Confirma remoção?")]),_c('div',[_c('v-btn',{staticClass:"btn-action option confirm-ok",attrs:{"color":"secondary","x-small":"","elevation":"0","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmDelete(item.id, true)}}},[_vm._v(" Sim ")]),_c('v-btn',{staticClass:"btn-action option",attrs:{"color":"red","x-small":"","elevation":"0","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmDelete(item.id, false)}}},[_vm._v(" Não ")])],1)]):_c('div',[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(item.stateFinalized),expression:"item.stateFinalized"}],attrs:{"size":20,"color":"gray","indeterminate":""}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!item.stateFinalized),expression:"!item.stateFinalized"}],staticClass:"btn-action",attrs:{"title":_vm.getButtonFinalizedComputed(item.finalized).title,"x-small":"","elevation":"1","rounded":"","plain":"","disabled":item.stateFinalized,"color":_vm.getButtonFinalizedComputed(item.finalized).color},on:{"click":function($event){return _vm.actionToggleStatusFinalized(item.id)}}},[_vm._v(" "+_vm._s(_vm.getButtonFinalizedComputed(item.finalized).title)+" ")]),_c('v-btn',{attrs:{"icon":"","title":"Editar"},on:{"click":function($event){return _vm.actionEdit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","title":"Deleter"},on:{"click":function($event){return _vm.actionDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }